<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
// import LoadingSpinner from "@/components/Loading";
import InputGroup from "@/components/newforms/input-group";
import SelectGroup from "@/components/newforms/select-group";
import UserType from "@/components/newforms/user-type";
// import StateOption from "@/components/forms/state-option";
import MessageModal from "@/components/modals/message-modal";
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";
// import "vue2-datepicker/index.css";

export default {
	name: "Edit_tenancy",
	components: {
		Layout,
		PageHeader,
		// LoadingSpinner,
		InputGroup,
		SelectGroup,
		UserType,
		MessageModal
	},
	data() {
		return {
			title: `Tenancy Edit`,
			items: [
				{
					text: "Tenancy",
				},
				{
					text: "Edit",
					active: true,
				},
			],
			property_sub_input: {
				storey: "",
				floor_level: "",
				other_floor_level: "",
			},
			// other_purpose: "",
			end_date: "",
			user_input: {
				title: "",
				property_type: "",
				purpose_of_use: "",
				purpose_of_use_name: "",
				size: "",
				size_unit: "sq_feet",
				property_title_desc: "",
				address_line_1: "",
				address_line_2: "",
				city: "",
				postal_code: "",
				state_id: "",
				country_id: 1,
				tenure: "",
				rental: [],
				rental_fee_structure: "",
				rental_due_date: "",
				fee_sharing: "",
				start: "",
				tenant_pre_terminate: "",
				pre_termination_month: "",
				renewable: "",
				renewable_notice_month: "",
				rental_deposit: "",
				utility_deposit: "",
				tenant_first_name: "",
				tenant_last_name: "",
				tenant_ic: "",
				tenant_phone: "",
				tenant_email: "",
				tenant_address_line_1: "",
				tenant_address_line_2: "",
				tenant_city: "",
				tenant_postal_code: "",
				tenant_state_id: "",
				tenant_country_id: "",
				tenant_company_name: "",
				tenant_company_reg: "",
				tenant_company_address: "",
				tenant_type: "individual",
				landlord_first_name: "",
				landlord_last_name: "",
				landlord_ic: "",
				landlord_company_name: "",
				landlord_company_reg: "",
				landlord_company_address: "",
				landlord_type: "individual",
				landlord_phone: "",
				landlord_email: "",
				landlord_address_line_1: "",
				landlord_address_line_2: "",
				landlord_city: "",
				landlord_postal_code: "",
				landlord_state_id: '',
				landlord_country_id: '',
				property_amenity_remarks: '',
				property_remarks: '',
				// amenities: []
			},
			amenities_selected: {},
			errors: [],
			property_type_options: [],
			purpose_options: [
				{ text: 'Residential', value: "residential" },
				{ text: 'Office', value: "office" },
				{ text: 'Warehouse', value: "warehouse" },
				{ text: 'Commercial', value: "commercial" },
				{ text: 'Agriculture', value: "agriculture" },
				{ text: 'Other, please specify', value: "others" },
			],
			size_options: [
				{ name: "Sq. feet", value: "sq_feet" },
				{ name: "Acre", value: "acre" },
				{ name: "Sq. meter", value: "sq_meter" },
			],
			fee_structure_options: [
				{ text: "Different for each years", value: "1" },
				{ text: "Same for all years", value: "0" },
			],
			yes_no_options: [
				{ text: "Yes", value: "1" },
				{ text: "No", value: "0" },
			],
			country_options: [
				{ text: "Malaysia", value: "1" },
				{ text: "Others", value: "2" },
			],
			fee_allocation_options: [
				{ text: "Landlord 100%", value: "landlord" },
				{ text: "Equally Share 50%", value: "equal" },
				{ text: "Tenant 100%", value: "tenant" },
			],
			storey_options: [],
			floor_options: [],
			state_options: [],
			amenity_options: [],
			redirect_page: false,
		};
	},
	methods: {
		htmlDecode(text) {
			var textArea = document.createElement('textarea');
			textArea.innerHTML = text;
			return textArea.value;
		},
		rental(e) {
			this.user_input.rental.push(e)
		},
		async submitForm() {
			this.errors = [];
			if (this.user_input.property_type == "intermediate_terrace_house") {
				this.user_input.property_type_desc = this.property_sub_input.storey
			} else if (this.user_input.property_type == "shoplot") {
				this.user_input.property_type_desc = this.property_sub_input.floor_level;
				if (this.property_sub_input.floor_level == "others")
					this.user_input.property_type_desc = this.property_sub_input.other_floor_level;
			}

			// if (this.user_input.purpose_of_use == 'others') {
			// 	this.user_input.purpose_of_use_name = this.user_input.purpose_of_use_name;
			// }

			let getAmenity = this.amenity_options.reduce((accumulator, currentValue) => {
				if (currentValue.selected) {
					accumulator.push({ "amenity_id": currentValue.value, "quantity": currentValue.quantity });
				}
				return accumulator;
			}, []);
			if (getAmenity.length > 0) {
				this.user_input.amenities = getAmenity;
			}

			await axios.put(`${process.env.VUE_APP_APIURL}/tenancy/update-tenancy/${this.$route.params.id}`, this.user_input, {
				headers: authHeader(),
			}).then((e) => {
				if (e.status == 200) {
					this.redirect_page = true;
					this.$refs.messageModal.showModal("Update Successfully!");
				}
			}).catch((error) => {
				if (error.response.status == 422 && error.response.data.code == 'invalid_field') {
					this.redirect_page = false;
					this.$refs.messageModal.showModal("Some fields are invalid.");
					const { data } = error.response;
					this.errors = data.errors;
				} else if (error.response.status == 422 && error.response.data.code == 'invalid_status') {
					this.redirect_page = false;
					this.$refs.messageModal.showModal(error.response.data.errors.status[0]);
				}
			});
		},

		async getPropertyType() {
			const result = await axios.get(`${process.env.VUE_APP_APIURL}/property-type`, {
				headers: authHeader(),
			}).catch(function (error) {
				console.log("Fail to get property type:" + error.message);
			});

			if (result.data.code === 'success') {
				const { data } = result.data;
				const terrace = data.find(e => e.property_type_key == 'intermediate_terrace_house');
				const shoplot = data.find(e => e.property_type_key == 'shoplot');
				if (terrace.sub_type.length > 0) {
					this.storey_options = terrace.sub_type.map(e => {
						return { "text": e.name, "value": e.property_type_sub_key }
					});
				}
				if (shoplot.sub_type.length > 0) {
					this.floor_options = shoplot.sub_type.map(e => {
						return { "text": e.name, "value": e.property_type_sub_key }
					});
				}
				this.floor_options.push({ text: "Others, please specify", value: "others" });
				this.property_type_options = data.map(e => {
					return { "text": e.name, "value": e.property_type_key }
				});
			}
		},

		async loadStateOptions() {
			const result = await axios.get(`${process.env.VUE_APP_APIURL}/state`, {
				headers: authHeader(),
			}).catch(function () {
				console.log("Fail to Load State Options");
			});

			if (result.data.code === 'success') {
				this.state_options = result.data.data.map(e => { return { "text": e.name, "value": e.id } })
			}
		},

		async loadTenancyData() {
			const result = await axios.get(`${process.env.VUE_APP_APIURL}/tenancy/${this.$route.params.id}`, {
				headers: authHeader(),
			}).catch(function (error) {
				alert("Tanancy getting error:" + error.message);
			});

			if (result.data.code === 'success') {
				const { tenancy: data } = result.data.data;
				Object.keys(this.user_input).forEach(e => {
					if (data[e] != null) {
						if (e != 'rental') {
							this.user_input[e] = this.htmlDecode(data[e]);
						} else {
							this.user_input[e] = JSON.parse(data[e]);
						}
					}
				})
console.log('asdsadasd', result.data.data)
				if (data.property_type == "intermediate_terrace_house" && data.property_type_desc != null) {
					this.property_sub_input.storey = data.property_type_desc;
				} else if (data.property_type == "shoplot" && data.property_type_desc != null) {
					// this.property_sub_input.storey = data.property_type.property_type_desc;
					let checkShoplot = this.floor_options.find(e => e.value == data.property_type_desc);
					this.property_sub_input.floor_level = checkShoplot == undefined ? 'others' : checkShoplot.value;
					this.property_sub_input.other_floor_level = data.property_type_desc;
				}

				// if (data.status != "draft") {

					// this.$refs.messageModal.showModal(`Only "draft" status is allowed to edit`);
					// this.redirect_page = true; 
				// }
				this.amenities_selected = data.amenities;
				data.amenities.forEach(e => {
					let item = this.amenity_options.findIndex(option => option.value == e.amenity_id);
					if (item != -1) {
						this.amenity_options[item].selected = true;
						this.amenity_options[item].quantity = e.quantity;
					}
				})
			} else if (result.data.code === 'permission_denied') {
				this.openMessageModal({ message: "Unable to view", redirect: '' });
			} else {
				this.openMessageModal({ message: "Item not found", redirect: '' });
			}
		},

		redirectPage() {
			if (this.redirect_page)
				this.$router.push({ "name": "Tenancy View New", params: { id: this.$route.params.id } })
		},

		async getAmenityList() {
			const result = await axios.get(`${process.env.VUE_APP_APIURL}/amenity-options`, {
				headers: authHeader(),
			}).catch(function (error) {
				alert("get amenity options:" + error.message);
			});
			if (result.data.code === 'success') {
				this.amenity_options = result.data.data.map((e) => { return { text: e.title, value: e.id, countable: e.is_countable, selected: false, quantity: 1 } });
			}

		},

		async minusQty(e) {
			if (this.amenity_options[e].selected == false) return;

			if (this.amenity_options[e].quantity == 1) {
				return;
			}

			this.amenity_options[e].quantity -= 1;
		},

		async addQty(e) {
			if (this.amenity_options[e].selected == false) return;
			this.amenity_options[e].quantity += 1;
		},

		async loadAmenity(callback) {
			let res = await this.getAmenityList();
			if (res) {
				callback();
			}
		}
	},
	computed: {
		formDataToWatch() {
			return Object.assign({}, this.user_input);
		},
	},
	async created() {
		this.getPropertyType();
		this.loadStateOptions();
		await this.getAmenityList();
		await this.loadTenancyData();

	},
	mounted() {
		// this.test.inputVal ='zxc'
	},
	watch: {
		// formDataToWatch: {
		user_input: {
			deep: true,
			handler: function (val) {
				if (val.start != "" && val.tenure != "") {
					let date = new Date(val.start);
					date.setFullYear(date.getFullYear() + parseInt(val.tenure));

					date.setDate(date.getDate() - 1);
					this.end_date = date.toISOString().split('T')[0];
				}

				if (val.tenant_type == "individual") {
					this.user_input.tenant_company_name = "";
					this.user_input.tenant_company_address = "";
					this.user_input.tenant_company_reg = "";
				}

				if (val.landlord_type == "individual") {
					this.user_input.landlord_company_name = "";
					this.user_input.landlord_company_reg = "";
					this.user_input.landlord_company_address = "";
				}
			}
		}
	},
};
</script>

<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<MessageModal ref="messageModal" @CloseBtn="redirectPage" />
		<!-- <LoadingSpinner :isLoading="false" />
		<MessageModal ref="messageModal" @CloseBtn="redirectPage" /> -->
		<div class="card" style="border-radius: 15px;">
			<div class="card-body">
				<div class="row">
					<h4 class="col-12 subTitle-font mb-3">Property Details</h4>
					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="property_title" name="property_title" v-model="user_input.title"
							label="Property Name" placeholder="Enter Property Name" :error="errors.title" />
					</div>
					<!-- </div> -->

					<!-- property sub -->
					<!-- <div class="row"> -->
					<div class="col-12 col-md-4 mb-3">
						<SelectGroup id="property_type" name="property_type" v-model="user_input.property_type"
							label="Property Type" placeholder="Enter Property Type" :error="errors.property_type"
							:options="property_type_options" />
					</div>
					<div class="col-12 col-md-4 mb-3"
						v-if="user_input.property_type && user_input.property_type == 'intermediate_terrace_house'">
						<SelectGroup id="storey" name="storey" v-model="property_sub_input.storey"
							label="Number of storey" placeholder="Enter Number of storey"
							:error="errors.property_type_desc" :options="storey_options" />
					</div>
					<div class="col-12 col-md-4 mb-3"
						v-if="user_input.property_type && user_input.property_type == 'shoplot'">
						<SelectGroup id="floor_level" name="floor_level" v-model="property_sub_input.floor_level"
							label="Floor Level" placeholder="Enter Floor Level" :error="errors.property_type_desc"
							:options="floor_options" />
					</div>
					<div class="col-12 col-md-4 mb-3"
						v-if="property_sub_input.floor_level && property_sub_input.floor_level == 'others'">
						<InputGroup id="floor_level_input" name="floor_level_input"
							v-model="property_sub_input.other_floor_level" label="Floor Level"
							placeholder="Enter Floor Level" :error="errors.property_type_desc" />
					</div>
					<!-- </div> -->

					<!-- purpose of use -->
					<!-- <div class="row"> -->
					<div class="col-12 col-md-4 mb-3">
						<SelectGroup id="purpose" name="purpose" v-model="user_input.purpose_of_use"
							label="Purpose Of Use" placeholder="Enter Purpose Of Use" :error="errors.purpose_of_use"
							:options="purpose_options" />
					</div>
					<div class="col-12 col-md-4 mb-3"
						v-if="user_input.purpose_of_use && user_input.purpose_of_use == 'others'">
						<InputGroup id="purpose_of_use_name" name="purpose_of_use_name" v-model="user_input.purpose_of_use_name"
							label="Specific purpose of use" placeholder="Specific purpose of use"
							:error="errors.purpose_of_use_name" />
					</div>
					<!-- </div> -->

					<!-- <div class="row"> -->
					<div class="col-12 col-md-4 mb-3">
						<label for="size" class="mb-0">Property Size</label>
						<div class="input-group">
							<select class="form-control" style="float: left; width: initial; flex: none"
								ref="optionsInput" v-model.trim="user_input.size_unit">
								<option v-for="option in size_options" :key="option.value" :value="option.value">
									{{ option.name }}
								</option>
							</select>
							<input id="size" name="size" class="form-control" placeholder="Size"
								v-model.trim="user_input.size" />
						</div>
						<b-form-invalid-feedback id="input-live-feedback" :state="false"
							v-if="errors.size && errors['size'].length > 0">
							{{ errors['size'][0] }}
						</b-form-invalid-feedback>
					</div>
					<!-- <div class="alert alert-danger mt-3" role="alert" v-if="errors.size && errors['size'].length > 0">
					{{ errors['size'][0] }}
					</div> -->

					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="property_title_desc" name="property_title_desc"
							v-model="user_input.property_title_desc" label="Property Title Desc"
							placeholder="Property Title Desc" :error="errors.property_title_desc" />
					</div>

					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="address1" name="address1" v-model="user_input.address_line_1"
							label="Address Line 1" placeholder="Enter Address Line 1" :error="errors.address_line_1" />
					</div>

					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="address2" name="address2" v-model="user_input.address_line_2"
							label="Address Line 2" placeholder="Enter Address Line 2" :error="errors.address_line_2" />
					</div>

					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="postal_code" name="postal_code" v-model="user_input.postal_code"
							label="Postal Code" placeholder="Enter Postal Code" :error="errors.postal_code" />
					</div>

					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="city" name="city" v-model="user_input.city" label="City"
							placeholder="Enter City" :error="errors.city" />
					</div>

					<div class="col-12 col-md-4 mb-3">
						<SelectGroup id="state" name="state" v-model="user_input.state_id" label="State"
							placeholder="Enter State" :error="errors.state_id" :options="state_options" />
					</div>
				</div>

				<!-- Rental Details -->
				<div class="row mt-5">
					<h4 class="col-12 subTitle-font mb-3">Rental Details</h4>
					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="tenure" name="tenure" v-model="user_input.tenure" label="Tenure"
							placeholder="Enter Tenure" :error="errors.tenure" type="number" min="1" />
					</div>

					<div class="col-12 col-md-4 mb-3">
						<SelectGroup id="rental_fee_structure" name="rental_fee_structure"
							v-model="user_input.rental_fee_structure" label="Rental Fee Structure"
							placeholder="Enter Fee Structure" :error="errors.rental_fee_structure"
							:options="fee_structure_options" />
					</div>
					<!-- </div> -->

					<!-- <div class="row"> -->
					<template v-if="parseInt(user_input.rental_fee_structure) == 1 && user_input.tenure != ''">
						<div class="col-12 col-md-4 mb-3" v-for="field, index in parseInt(user_input.tenure)"
							:key="field">
							<InputGroup :id="'rental_' + field" :name="'rental_' + field"
								v-model="user_input.rental[index]" :label="'Year ' + field + ' Rental Fee'"
								placeholder="Enter Rental Fee" :error="errors.rental" type="number" min="0" />
						</div>
					</template>
					<template v-else>
						<div class="col-12 col-md-4 mb-3">
							<InputGroup id="rental" name="rental" v-model="user_input.rental[0]" label="Rental Fee"
								placeholder="Enter Rental Fee" :error="errors.rental" type="number" min="0" />
						</div>
					</template>
					<!-- </div> -->

					<!-- <div class="row mt-2"> -->
					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="rental_due" name="rental_due" v-model="user_input.rental_due_date"
							label="Rental Due Date" placeholder="Enter the day number, like 28"
							:error="errors.rental_due_date" type="number" min="0" max="28" />
					</div>
					<div class="col-12 col-md-4 mb-3">
						<SelectGroup id="rental_fee_structure" name="rental_fee_structure"
							v-model="user_input.fee_sharing" label="Fee Allocation" placeholder="Enter Fee Structure"
							:error="errors.fee_sharing" :options="fee_allocation_options" />
					</div>

					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="start" name="start" v-model="user_input.start" label="Start Date"
							placeholder="Enter Start Date" :error="errors.start" type="date" />
					</div>

					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="end" name="end" v-model="end_date" label="End Date" placeholder="Enter End Date"
							type="date" :disabled="true" />
					</div>
					<!-- </div>

				<div class="row"> -->
					<!-- <div class="col-12 col-md-4 mb-3">
						<SelectGroup id="pre-terminate" name="pre-terminate" v-model="user_input.tenant_pre_terminate"
							label="Is tenant allowed To pre-terminate?"
							placeholder="Enter Prior Notice of Pre-Termination" :error="errors.tenant_pre_terminate"
							:options="yes_no_options" />
					</div>
					<div class="col-12 col-md-4 mb-3" v-if="parseInt(user_input.tenant_pre_terminate) == 1">
						<InputGroup id="pre-terminate-month" name="pre-terminate-month"
							v-model="user_input.pre_termination_month" label="Prior Notice of Pre-Termination"
							placeholder="Enter Prior Notice of Pre-Termination" :error="errors.pre_termination_month"
							type="number" min="1" />
					</div> -->
					<!-- </div> -->

					<!-- <div class="row"> -->
					<div class="col-12 col-md-4 mb-3">
						<SelectGroup id="renewable" name="renewable" v-model="user_input.renewable" label="Renewable?"
							placeholder="Enter Renewable" :error="errors.renewable" :options="yes_no_options" />
					</div>
					<div class="col-12 col-md-4 mb-3" v-if="parseInt(user_input.renewable) == 1">
						<InputGroup id="renewable_notice_month" name="renewable_notice_month"
							v-model="user_input.renewable_notice_month" label="Prior Notice of Renewal"
							placeholder="Enter Prior Notice of Renewal" :error="errors.renewable_notice_month"
							type="number" min="1" />
					</div>
					<!-- </div>

				<div class="row"> -->
					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="rental_deposit" name="rental_deposit" v-model="user_input.rental_deposit"
							label="Rental Deposit" placeholder="Enter Rental Deposit" :error="errors.rental_deposit"
							type="number" min="0" />
					</div>

					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="utility_deposit" name="utility_deposit" v-model="user_input.utility_deposit"
							label="Utility Deposit" placeholder="Enter Utility Deposit" :error="errors.utility_deposit"
							type="number" min="0" />
					</div>
				</div>

				<div class="row mt-5">
					<h4 class="col-12 subTitle-font pb-0 mb-3">Tenant Details</h4>

					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="tenant_first_name" name="tenant_first_name"
							v-model="user_input.tenant_first_name" label="First Name" placeholder="Enter First Name"
							:error="errors.tenant_first_name" type="text" />
					</div>

					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="tenant_last_name" name="tenant_last_name" v-model="user_input.tenant_last_name"
							label="Last Name" placeholder="Enter Last Name" :error="errors.tenant_last_name"
							type="text" />
					</div>

					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="tenant_ic" name="tenant_ic" v-model="user_input.tenant_ic" label="NRIC"
							placeholder="Enter NRIC" :error="errors.tenant_ic" type="text" />
					</div>

					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="tenant_phone" name="tenant_phone" v-model="user_input.tenant_phone"
							label="Phone" placeholder="Enter Phone" :error="errors.tenant_phone" type="number" />
					</div>

					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="tenant_email" name="tenant_email" v-model="user_input.tenant_email"
							label="Email" placeholder="Enter Email" :error="errors.tenant_email" type="email" />
					</div>

					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="tenant_address_line_1" name="tenant_address_line_1"
							v-model="user_input.tenant_address_line_1" label="Address Line 1"
							placeholder="Enter Address Line 1" :error="errors.tenant_address_line_1" type="text" />
					</div>

					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="tenant_address_line_2" name="tenant_address_line_2"
							v-model="user_input.tenant_address_line_2" label="Address Line 2"
							placeholder="Enter Address Line 2" :error="errors.tenant_address_line_2" type="text" />
					</div>

					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="tenant_postal_code" name="tenant_postal_code"
							v-model="user_input.tenant_postal_code" label="Postal Code" placeholder="Enter Postal Code"
							:error="errors.tenant_postal_code" type="text" />
					</div>

					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="tenant_city" name="tenant_city" v-model="user_input.tenant_city" label="City"
							placeholder="Enter City" :error="errors.tenant_city" type="text" />
					</div>

					<div class="col-12 col-md-4 mb-3">
						<SelectGroup id="tenant_state" name="tenant_state" v-model="user_input.tenant_state_id"
							label="State" placeholder="Enter State" :error="errors.tenant_state_id"
							:options="state_options" />
					</div>

					<div class="col-12 col-md-4 mb-3">
						<SelectGroup id="tenant_country" name="tenant_country" v-model="user_input.tenant_country_id"
							label="Country" placeholder="Enter Country" :error="errors.tenant_country_id"
							:options="country_options" />
					</div>
				</div>

				<div class="row">
					<div class="col-12 col-md-4 mb-3">
						<UserType id="tenant_type" name="tenant_type" v-model="user_input.tenant_type" label="Type" />
					</div>
				</div>

				<div class="row">
					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="tenant_comp_name" name="tenant_comp_name"
							v-model="user_input.tenant_company_name" label="Company Name (optional)"
							placeholder="Enter Company Name" :error="errors.tenant_company_name"
							:options="country_options" :disabled="user_input.tenant_type == 'individual'" />
					</div>

					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="tenant_comp_reg" name="tenant_comp_reg" v-model="user_input.tenant_company_reg"
							label="Company Registration No. (optional)" placeholder="Enter Company Registration No."
							:error="errors.tenant_company_reg" :options="country_options"
							:disabled="user_input.tenant_type == 'individual'" />
					</div>

					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="tenant_comp_address" name="tenant_comp_address"
							v-model="user_input.tenant_company_address" label="Address (optional)"
							placeholder="Enter Address" :error="errors.tenant_company_address"
							:options="country_options" :disabled="user_input.tenant_type == 'individual'" />
					</div>
				</div>

				<div class="row mt-5">
					<h4 class="col-12 subTitle-font pb-0 mb-3">Landlord Details</h4>

					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="landlord_first_name" name="landlord_first_name"
							v-model="user_input.landlord_first_name" label="First Name" placeholder="Enter First Name"
							:error="errors.landlord_first_name" type="text" />
					</div>

					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="landlord_last_name" name="landlord_last_name"
							v-model="user_input.landlord_last_name" label="Last Name" placeholder="Enter Last Name"
							:error="errors.landlord_last_name" type="text" />
					</div>

					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="landlord_ic" name="landlord_ic" v-model="user_input.landlord_ic" label="NRIC"
							placeholder="Enter NRIC" :error="errors.landlord_ic" type="text" />
					</div>

					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="landlord_phone" name="landlord_phone" v-model="user_input.landlord_phone"
							label="Phone" placeholder="Enter Phone" :error="errors.landlord_ic" type="number" />
					</div>

					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="landlord_email" name="landlord_email" v-model="user_input.landlord_email"
							label="Email" placeholder="Enter Email" :error="errors.landlord_email" type="email" />
					</div>

					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="landlord_address_line_1" name="landlord_address_line_1"
							v-model="user_input.landlord_address_line_1" label="Address Line 1"
							placeholder="Enter Address Line 1" :error="errors.landlord_address_line_1" type="text" />
					</div>

					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="landlord_address_line_2" name="landlord_address_line_2"
							v-model="user_input.landlord_address_line_2" label="Address Line 2"
							placeholder="Enter Address Line 2" :error="errors.landlord_address_line_2" type="text" />
					</div>

					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="landlord_postal_code" name="landlord_postal_code"
							v-model="user_input.landlord_postal_code" label="Postal Code"
							placeholder="Enter Postal Code" :error="errors.landlord_postal_code" type="text" />
					</div>

					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="landlord_city" name="landlord_city" v-model="user_input.landlord_city"
							label="City" placeholder="Enter City" :error="errors.landlord_city" type="text" />
					</div>

					<div class="col-12 col-md-4 mb-3">
						<SelectGroup id="landlord_state" name="landlord_state" v-model="user_input.landlord_state_id"
							label="State" placeholder="Enter State" :error="errors.landlord_state_id"
							:options="state_options" />
					</div>

					<div class="col-12 col-md-4 mb-3">
						<SelectGroup id="landlord_country" name="landlord_country"
							v-model="user_input.landlord_country_id" label="Country" placeholder="Enter Country"
							:error="errors.landlord_country_id" :options="country_options" />
					</div>
				</div>

				<div class="row">
					<div class="col-12 col-md-4 mb-3">
						<UserType id="landlord_type" name="landlord_type" v-model="user_input.landlord_type"
							label="Type" />
					</div>
				</div>

				<div class="row">
					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="landlord_comp_name" name="landlord_comp_name"
							v-model="user_input.landlord_company_name" label="Company Name (optional)"
							placeholder="Enter Company Name" :error="errors.landlord_company_name"
							:options="country_options" :disabled="user_input.landlord_type == 'individual'" />
					</div>

					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="landlord_comp_reg" name="landlord_comp_reg"
							v-model="user_input.landlord_company_reg" label="Company Registration No. (optional)"
							placeholder="Enter Company Registration No." :error="errors.landlord_company_reg"
							:options="country_options" :disabled="user_input.landlord_type == 'individual'" />
					</div>

					<div class="col-12 col-md-4 mb-3">
						<InputGroup id="landlord_comp_address" name="landlord_comp_address"
							v-model="user_input.landlord_company_address" label="Address (optional)"
							placeholder="Enter Address" :error="errors.landlord_company_address"
							:options="country_options" :disabled="user_input.landlord_type == 'individual'" />
					</div>
				</div>

				<div class="row mt-5">
					<div class="col-12 col-md-6">
						<h4 class="subTitle-font col-12 pl-0">Amenities</h4>
						<div class="w-100 d-flex"
							:class="{ 'mb-3': amenity.countable === 0, 'mb-2': amenity.countable !== 0 }"
							v-for="amenity, index in amenity_options" :key="amenity.value">
							<div class="form-check w-75">
								<input class="form-check-input" type="checkbox" :value="amenity.value"
									:id="'amenity_' + index" v-model="amenity_options[index]['selected']"
									name="amenity">
								<label class="form-check-label" :for="'amenity_' + index">
									{{ amenity.text }}
								</label>
							</div>
							<div class="w-25" v-if="amenity.countable == 1">
								<button class="amenity_text amenity_qty amenity_qty_minus" @click="minusQty(index)"
									:class="amenity_options[index]['selected'] == false ? ' deselect_qty' : ''">-</button>
								<span class="amenity_text">{{ amenity.quantity }}</span>
								<button class="amenity_text amenity_qty amenity_qty_add" @click="addQty(index)"
									:class="amenity_options[index]['selected'] == false ? ' deselect_qty' : ''">+</button>
							</div>
						</div>
					</div>
				</div>

				<div class="row mt-3">
					<div class="col-12 col-md-4 mb-3">
						<label class="mb-0" for="property_amenity_remarks">Property Amenity Remarks</label>
						<textarea class="form-control" rows="5" id="property_amenity_remarks"
							placeholder="Property Amenity Remarks"
							v-model="user_input.property_amenity_remarks"></textarea>
					</div>

					<div class="col-12 col-md-4 mb-3">
						<label class="mb-0" for="property_remarks">Property Remarks</label>
						<textarea class="form-control" rows="5" id="property_remarks" placeholder="Property Remarks"
							v-model="user_input.property_remarks"></textarea>
					</div>
				</div>

				<div>
					<button @click="submitForm" class="btn btn-orange">Save</button>
				</div>
			</div>
		</div>
	</Layout>
</template>

<style scoped lang="scss">
.amenity_qty {
	border: 1px solid #EAEAEA;
	border-radius: 100%;
	background: white;
	width: 25px;
	margin: 0 10px;
}

.amenity_text {
	font-size: 16px;
	color: black;
}

.deselect_qty {
	color: #EAEAEA;
}
</style>
